export enum Resources {
    ACCESS_LOG = 'access_log',
    ACL = 'acl',
    ANSIBLE_LOG = 'ansible_log',
    APPLICATION = 'application',
    APPLICATION_INFO_FIELDS = 'application_info_fields',
    BLACK_LIST = 'black_list',
    ORGANISATION_BLACK_LIST = 'organisation_black_list',
    CAMPAIGN = 'campaign',
    PWA = 'pwa',
    DOMAIN = 'domain',
    PIXEL = 'pixel',
    REVIEW = 'review',
    GEO = 'geo',
    OFFER = 'offer',
    DIRECT_OFFER_WEBSITE = 'direct_offer_website',
    PUSH_CAMPAIGN = 'push_campaign',
    POSTBACK_LOG = 'postback',
    CLICK = 'click',
    WEB_CLICK = 'web_click',
    PWA_CLICK = 'pwa_click',
    SETTINGS = 'settings',
    MONITORING_SETTINGS = 'monitoring_settings',
    REPORT = 'reports',
    APPLICATION_REPORT = 'report_application',
    OFFER_REPORT = 'report_offer',
    OFFER_REPORT_BY_MEDIABUYER = 'report_offer_by_mediabuyer',
    CAMPAIGN_REPORT = 'report_campaign',
    CAMPAIGN_BY_GEO_REPORT = 'report_campaign_by_geo',
    CAMPAIGN_BY_GEO_BY_OFFER_REPORT = 'report_campaign_by_geo_by_offer',
    PWA_CAMPAIGN_BY_GEO_BY_OFFER_REPORT = 'pwa_report_campaign_by_geo_by_offer',
    PWA_CAMPAIGN_BY_GEO_BY_UTM_REPORT = 'pwa_report_campaign_by_geo_by_utm',
    DOW_CAMPAIGN_BY_GEO_BY_OFFER_REPORT = 'dow_report_campaign_by_geo_by_offer',
    DOW_CAMPAIGN_BY_GEO_BY_UTM_REPORT = 'dow_report_campaign_by_geo_by_utm',
    CONVERSION_REPORT = 'report_conversion',
    GEO_REPORT = 'report_geo',
    EVENT = 'event',
    WEB_EVENT = 'web_event',
    PWA_EVENT = 'pwa_event',
    HOME_PAGE = 'home_page',
    DASHBOARD = 'dashboard',
    POSTBACK_OFFER_ROUTE = 'postback_offer_route',
    POSTBACK_ROUTE_RULE = 'postback_route_rule',
    POSTBACK_ROUTING_LOG = 'postback_routing_log',
    PROXY = 'proxy',
    PUSH = 'push',
    PUSH_SET = 'push_set',
    PUSH_SCHEDULE_TEMPLATE = 'push_schedule_template',
    PUSH_TEMPLATE = 'push_template',
    ROLE = 'role',
    SECURITY_LINK = 'security_link',
    THREAT_MONITORING = 'tracker_monitoring',
    USER = 'user',
    USER_AGENT_BAN = 'user_agent_ban',
    VPN = 'vpn',
    WEBPAGE = 'webpage',
    WEBSITE = 'website',
    ID_BLACK_LIST = 'id_black_list',
    WHITE_LIST = 'white_list',
    MONITORING_WHITE_LIST = 'monitoring_white_list',
    PLATFORM = 'platform',
    IMAGES = 'images',
    PROFILE = 'profiles',
}

export const AFFILIATE_RESOURCES = [
    Resources.CAMPAIGN,
    Resources.OFFER_REPORT_BY_MEDIABUYER,
    Resources.GEO_REPORT,
    Resources.OFFER_REPORT,
    Resources.CAMPAIGN_BY_GEO_REPORT,
    Resources.CAMPAIGN_REPORT,
    Resources.CAMPAIGN_BY_GEO_BY_OFFER_REPORT,
    Resources.PWA_CAMPAIGN_BY_GEO_BY_OFFER_REPORT,
    Resources.PWA_CAMPAIGN_BY_GEO_BY_UTM_REPORT,
    Resources.APPLICATION_REPORT,
    Resources.CONVERSION_REPORT,
];
